<template>
  <NuxtImg
     quality="80" class="md:hidden block w-full" src="/mobile_reviews_sections.png" loading="lazy"
    alt="mobile_reviews_sections"
  />
  <section class="hidden md:block">
    <div class="w-full bg-[#383D44] md:h-[400px] lg:h-[633px]">
      <div class="max-w-[1012px] w-full px-4 mx-auto md:pt-14 lg:pt-28 pb-16  md:pb-24 flex flex-row gap-10 text-white">
        <div>
          <h2 class="text-m-h2 lg:text-h2">
            Отзывы без Ранга
          </h2>
          <div class="relative md:scale-[0.6] lg:scale-100">
            <div class="max-w-[90px] absolute top-[130px]">
              <NuxtImg  quality="70" loading="lazy" src="/emojibw3.png" alt="emoji" />
              <p class="mt-2 text-primary">
                Вас посетил клиент
              </p>
            </div>
            <NuxtImg
               quality="70" loading="lazy" class="absolute top-[84px] left-[90px]" width="182px"
              src="/arrowbw1.svg" alt="arrow"
            />
            <div class="w-[160px] absolute top-[56px] left-[244px]">
              <NuxtImg  quality="70" loading="lazy" class="w-1/2 mx-auto" src="/emojibw1.png" alt="emoji" />
              <p class="mt-2 text-primary">
                Ему понравилось
              </p>
            </div>
            <div class="w-[160px]  absolute top-[210px] left-[244px]">
              <NuxtImg  quality="70" loading="lazy" class="w-1/2 mx-auto" src="/emojibw2.png" alt="emoji" />
              <p class="mt-2 text-primary">
                Не понравилось
              </p>
            </div>
            <div class="w-[160px]  absolute top-[84px] left-[356px]">
              <NuxtImg
                 quality="70" loading="lazy" class="mx-auto" width="107px" height="24px" src="/arrowbw_short.svg"
                alt="arrow"
              />
            </div>
            <div class="w-[200px] absolute top-[66px] left-[500px]">
              <NuxtImg  quality="70" loading="lazy" width="60px" height="60px" src="×.svg" alt="close" />
              <p class="mt-2 text-primary">
                Не оставляет отзыв, занимается своими делами
              </p>
            </div>
            <div class="w-[312px] absolute top-[236px] left-[380px]">
              <NuxtImg
                 quality="70" loading="lazy" class="mx-auto" width="312px" height="24px" src="/arrow56.svg"
                alt="arrow"
              />
            </div>
            <div class="w-[256px] absolute top-[224px] left-[720px]">
              <NuxtImg
                 quality="70" loading="lazy" class="mx-auto" width="256" height="47" src="/negative_stars.svg"
                alt="stars"
              />
              <p class="mt-2 text-primary">
                Пишет негативный отзыв в интернете, на который уже нельзя повлиять
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="hidden md:block">
    <div class="w-full bg-blue-500 md:h-[400px] lg:h-[633px]">
      <div class="max-w-[1012px] w-full px-4 mx-auto md:pt-14 lg:pt-28 pb-16  md:pb-24 flex flex-row gap-10 text-white">
        <div>
          <h2 class="text-m-h2 lg:text-h2">
            Отзывы с Рангом
          </h2>
          <div class="relative md:scale-[0.6] lg:scale-100">
            <div class="max-w-[90px] absolute top-[130px]">
              <NuxtImg
                
                quality="70" loading="lazy"
                src="/emoji1.png"
                alt="emoji"
              />
              <p class="mt-2 text-primary">
                Вас посетил клиент
              </p>
            </div>
            <NuxtImg
              
              quality="70" loading="lazy"
              class="absolute top-[84px] left-[90px]" width="182px" src="/arrow1.svg"
              alt="arrow"
            />
            <div class="w-[160px] absolute top-[56px] left-[244px]">
              <NuxtImg
                
                quality="70" loading="lazy"
                class="mx-auto w-1/2" src="/emoji2.png"
                alt="emoji"
              />
              <p class="mt-2 text-primary">
                Ему понравилось
              </p>
            </div>
            <div class="w-[160px] absolute top-[190px] left-[244px]">
              <NuxtImg
                
                quality="70" loading="lazy"
                class="mx-auto w-1/2" src="/emoji3.png"
                alt="emoji"
              />
              <p class="mt-2 text-primary">
                Не понравилось
              </p>
            </div>

            <div class="w-[312px] absolute top-[84px] left-[380px]">
              <NuxtImg
                
                quality="70" loading="lazy"
                class="mx-auto" width="312px" height="23px" src="/arrow_long.svg"
                alt="arrow"
              />
            </div>
            <div class="w-[256px] absolute top-[72px] left-[720px]">
              <NuxtImg
                
                quality="70" loading="lazy"
                class="mx-auto" width="256" height="47" src="/good_stars.svg"
                alt="stars"
              />
              <p class="mt-2 text-primary">
                Позитивный отзыв попадает в интернет и привлекает новых клиентов
              </p>
            </div>
            <div class="w-[160px] h-[114px] absolute top-[244px] left-[356px]">
              <NuxtImg
                
                quality="70" loading="lazy"
                class="mx-auto" width="100" height="114" src="/arrow_rotate.svg"
                alt="arrow"
              />
            </div>
            <div class="w-[300px] flex  absolute top-[376px] left-[447px]">
              <NuxtImg
                
                quality="70" loading="lazy"
                width="60px" height="60px" class="self-start" src="/emoji_solve.png"
                alt="emoji doctor"
              />
              <p class="mt-2 text-primary">
                Конфликт быстро решается внутри
              </p>
            </div>
            <div class="w-[240px] inline-block absolute top-[180px] left-[447px]">
              <NuxtImg
                
                quality="30"
                loading="lazy"
                width="65px" height="60px" class="float-left pr-3" src="/message_icon.svg"
                alt="message icon"
              />
              <p class="text-primary-small text-[#FFE034]">
                Ранг предлагает пожаловаться директору
              </p>
            </div>
            <div class="w-[240px] inline-block absolute top-[34px] left-[447px]">
              <NuxtImg
                
                quality="30"
                loading="lazy"
                width="65px" height="60px" class="float-left pr-3" src="/message_icon.svg"
                alt="message icon"
              />
              <p class="text-primary-small text-[#FFE034]">
                Ранг мотивирует оставить отзыв
              </p>
            </div>
            <div class="w-[80px] flex flex-col items-start gap-6 absolute top-[110px] left-[120px]">
              <NuxtImg
                
                quality="30"
                loading="lazy"
                width="65px" height="60px" class="pr-3" src="/message_icon.svg"
                alt="message icon"
              />
              <p class="text-primary-small text-[#FFE034]">
                Получает СМС
                от Ранга
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
